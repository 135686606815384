<template>
    <div class="collection">
        <collection-top
            :allTypes="['PRODUCT', 'VENDOR']"
            :type.sync="type"
            :searchKey.sync="searchKey"
            @submitSearch="submitSearch"
        >
            <template slot="right">
                <el-button type="info" size="small" plain v-if="!isEdit" @click="isEdit = true">{{$t('qing-chu-ji-lu')}}</el-button>
                <template v-else>
                    <el-button type="info" size="small" plain @click="brantch" v-if="chooseList.length > 0"
                        >{{$t('pi-liang-qing-chu')}}</el-button
                    >
                    <el-button type="info" size="small" plain @click="isEdit = false">{{$t('wan-cheng')}}</el-button>
                </template>
            </template>
        </collection-top>

        <div class="el-main" v-loading="loading">
            <div class="mainList" ref="list">
                <el-row :gutter="30" type="flex">
                    <el-col v-for="item in list" :key="item.id">
                        <product-gride
                            :info="item"
                            :isNew="false"
                            shadow
                            :hasChoose="isEdit"
                            :chooseList.sync="chooseList"
                            v-if="type === 'PRODUCT'"
                        ></product-gride>

                        <brand-gride
                            :info="item"
                            shadow
                            :hasChoose="isEdit"
                            :chooseList.sync="chooseList"
                            v-else
                        ></brand-gride>
                    </el-col>
                </el-row>
            </div>
            <empty-page v-if="empty">
                <img slot="img" :src="require('@/assets/kong_img_jilu.png')" />
                <span slot="text">{{ $t('zan-wu-liu-lan-ji-lu') }}</span>
            </empty-page>
        </div>
        <el-footer>
            <el-pagination
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </el-footer>
    </div>
</template>
<script>
import pageableList from '../../mixins/pageableList';
import CollectionTop from '../../components/collection/Top';
import ProductGride from '../../components/product/Gride';
import BrandGride from '../../components/brand/Gride';
import { collectType } from '../../utils/AppState';

export default {
    name: 'collection',
    mixins: [pageableList],
    data() {
        return {
            url: '/collect/my',
            httpType: 'get',
            type: 'PRODUCT',
            chooseList: [],
            isEdit: false
        };
    },
    computed: {
        listQuery() {
            return {
                type: this.type,
                isCollect: false
            };
        },
        typeName() {
            return collectType.get(this.type);
        }
    },
    watch: {
        listQuery() {
            this.page=1;
            this.getData();
        },
        type() {
            this.isEdit = false;
            this.chooseList = [];
        }
    },
    mounted() {
        this.getData();
    },
    components: {
        CollectionTop,
        ProductGride,
        BrandGride
    },
    methods: {
        brantch() {
            this.$http
                .post('/collect/batchCancel', {
                    type: this.type,
                    ids: this.chooseList.join(','),
                    isCollect: false
                })
                .then(res => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.getData();
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/pagebleTable';
@import '../../styles/variables';

.route {
    background-color: transparent !important;
}

.el-main {
    margin: 20px 0 0;
		// min-height: calc(max(100%, 1000px) - 136px);
		
    @include max-height(136px, $max: 1200px);
    overflow: hidden;
}
</style>
